















































import LandingSection from '@/components/LandingSection.vue'
import { normalizeResourceName } from '@/utils/misc'
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'LandingSectionClients',

  components: {
    LandingSection,
  },

  setup() {
    const clientsConfiguration = [
      {
        name: 'Agrinos',
        website: 'https://agrinos.com/',
      },
      {
        name: 'arbol2000',
        website: 'https://www.arbol2000.com/',
      },
      {
        name: 'CALIDRA',
        website: 'https://calidra.com/',
      },
      {
        name: 'Featherland',
        website: 'http://featherlandegg.com/',
      },
      {
        name: 'GRUPO ALTA',
        website: 'http://www.grupoalta.com/',
      },
      {
        name: 'GRUPO CARTER',
        website: '',
      },
      {
        name: 'Grupo México',
        website: 'https://www.gmexico.com/',
      },
      {
        name: 'GRUPO MODELO',
        website: 'https://www.grupomodelo.com/',
      },
      {
        name: 'GRUPO SOLES',
        website: 'http://www.soles.com.mx/',
      },
      {
        name: 'Holcim',
        website: 'https://www.holcim.com.mx/',
      },
      {
        name: 'HUEVO San Juan',
        website: 'https://huevosanjuan.com.mx/',
      },
      {
        name: 'Incono',
        website: 'http://www.inconno.com/',
      },
      {
        name: 'JUMEX',
        website: 'http://www.jumex.com/',
      },
      {
        name: 'm3',
        website: 'https://m3eng.com/',
      },
      {
        name: 'MAGNA',
        website: 'https://www.magna.com/',
      },
      {
        name: 'MINERA PLATA REAL',
        website: '',
      },
      {
        name: 'norson',
        website: 'http://www.norson.net/',
      },
      {
        name: 'PEAL',
        website: 'https://www.peal.es/',
      },
      {
        name: 'PEDRO LÓPEZ ELÍAS',
        website: 'https://www.ccple.org/',
      },
      {
        name: 'PROAN',
        website: 'http://proan.com/',
      },
      {
        name: 'QUANTUM',
        website: 'https://infraquantum.com/',
      },
      {
        name: 'RIOblanco',
        website: 'http://www.rioblanco.net/',
      },
      {
        name: 'Sonora Forning Technologies',
        website: 'https://www.magna.com/',
      },
      {
        name: 'TALLER CAPITAL',
        website: 'http://tallercapital.mx/',
      },
    ]

    const clients = clientsConfiguration.map(({ name, website }) => ({
      title: `${name} logo`,
      website: website,
      imageSrc: `@/assets/images/clients/${normalizeResourceName(name)}.png`,
    }))

    return {
      clients,
    }
  },
})
